html, body #root {
  height: 100vh;
  z-index: -1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  background-color: #130000;
  overflow-y: auto;
  overflow-x: hidden;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.thumbnail {
  height: 200px;
  width: 200px;
}

.interactableCard{
  width: 250px ;
  height: 350px ;
  cursor: "pointer";
  transition: all .2s ease-in-out;
  scale:1;
  background-color: green;
  overflow:hidden;
}

.interactableCard:hover{
  -webkit-transform: scale(1.1, 1.1)!important;
  transform: scale(1.1, 1.1)!important;
  transform: scale(1.1, 1.1);       /* W3C */
-webkit-transform: scale(1.1, 1.1); /* Safari & Chrome */
-moz-transform: scale(1.1, 1.1);    /* Firefox */
-ms-transform: scale(1.1, 1.1);     /* Internet Explorer */
-o-transform: scale(1.1, 1.1);      /* Opera */
  
}

.cardImageProperties{
  height: 100%!important;
  width: 100%!important;
}

.cardCommonBorder{
  box-shadow: 0 0 20px 0 grey;
}

.toggleButton{
  opacity: 0.5;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.toggleButton:hover{
  opacity: 0.9;
}


.buttonRibbon{
  background-color: #082e02!important;
  color: #d2d825!important;
  border: 2px solid #d2d825!important; 
  font-family: "Fantasy", "Papyrus", 'monospace';
  font-weight: bold;
	clip-path: polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%)!important;
  -webkit-transform: scale(0.95, 0.95)!important;
  transform: scale(0.95, 0.95);       /* W3C */
  -webkit-transform: scale(0.95, 0.95); /* Safari & Chrome */
  -moz-transform: scale(0.95, 0.95);    /* Firefox */
  -ms-transform: scale(0.95, 0.95);     /* Internet Explorer */
  -o-transform: scale(0.95, 0.95);      /* Opera */
  transition: all .2s ease-in-out;
}

.buttonRibbon:hover{
  background-color: #d2d825!important;
  border: 2px solid white!important;
  color: #082e02!important;
  -webkit-transform: scale(1, 1)!important;
  transform: scale(1, 1);       /* W3C */
  -webkit-transform: scale(1, 1); /* Safari & Chrome */
  -moz-transform: scale(1, 1);    /* Firefox */
  -ms-transform: scale(1, 1);     /* Internet Explorer */
  -o-transform: scale(1, 1);      /* Opera */
}

.buttonRibbon:disabled{
  filter: grayscale(100%);
}

.buttonCancel{
  background-color: #8f0005!important;
  color: white!important;
  border: 1px solid #f3e2a2!important;
  border-radius: 5!important;

}
.buttonCancel:hover{
  background-color: purple!important;
  color: white!important;
  border: 1px solid pink!important;
  border-radius: 5!important;
  scale: 105%!important;
  transition: 0.5s!important;
}

.buttonCancel:disabled{
  filter: grayscale(100%);
  scale: 90%;
}

.videoStyles{
  margin-top: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100vw!important;
  min-height: 100%;
  width: 100vw!important;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.socialIcon{
  opacity: 0.7;  
}

.socialIcon:hover{
  opacity: 1;
}

.footerStyles{
  display: flex;
  justify-content: center;
  color:black!important;
  width: 100%!important;
  background-color: #121212;
  z-index:100!important;
  height:70px!important;
  border-radius:0px!important;
  border-bottom: 1px solid var(--menu-bar-bottom-border)!important;
  filter: drop-shadow(0 0 10px #000);
}

.flexButtonContainer{
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.flexButtonSeparator{
  width: 100%;
}

.viewSizing{
  position:relative!important;
  top:0px!important;

  align-content:center!important;
  width:100%!important;
  height:100%!important;

  z-index:220!important;
  
}

.homeImageSizing{ 
  position:relative!important;
  top:0px!important;
  overflow-x:hidden!important;
  align-content:center!important;
  width:100%!important;
  height:100%!important;
  max-height:1080px!important;
  max-width:2560px!important;
  min-height:1080px!important;
  min-width:2560px!important;
  z-index:0!important;
}

.st0{
  fill:#0a2900;
  stroke:#b3b645;
  stroke-width:5;
  stroke-miterlimit:10;
}
.st1{
  fill:none;
  stroke:#d2d825;
  stroke-width:5;
  stroke-linecap:square;
  stroke-miterlimit:10;
}
.st2{
  fill:#D6EC00;
}
.st3{
  font-family:'TrajanPro3-Semibold';
}
.st4{
  font-size:48px;

}
.st5{
  fill:url(#SVGID_1_);
  stroke:#b3b645;
  stroke-width:5;
  stroke-linecap:square;
  stroke-miterlimit:10;
}
.st6{
  fill:#0a2900;
}
.svgButtonFont{
  font-family: trajan-pro-3, serif;
  font-weight: 700;
  font-style: normal;
}


.deckSelectMenuItems{
  color:#d2d825!important;
}
.deckSelectMenuItems:hover{
  background-color: #d2d825!important;
  color:#0a2900!important;
}

.newdc{
    text-align: center; 
    color: white;
    font-size: 32pt; 

    background: /* gradient can be an image */
    linear-gradient(
      to left, 
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,1) 50%,
      rgba(255,255,255,0) 100%
    )
    left 
    bottom
    no-repeat; 
  background-size:100% 1px ;/* if linear-gradient, we need to resize it */
}
.cardsInDeckTopDivider{
  border-top:8px ridge #0a0;

}

.sectionHeaders {
  width: 100%;
  color: white;
  font-family: trajan-pro-3, serif;
  text-align: center;
}

@media (min-width:300px){
  .sectionHeaders{
    font-size: 1.5rem;
  }
}

@media (min-width:550px){
  .sectionHeaders{
    font-size: 2rem;
  }
}

@media (min-width:800px){
  .sectionHeaders{
    font-size: 3rem;
  }
}
@media (min-width:1100px){
  .sectionHeaders{
    font-size: 3.5rem;
  }
}

.updateCard{

  padding: 0px;
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
  border-color: white;
  border: 1px solid;
  height: 300px;
  display: flex;

  flex-direction: column;

}

.topSectionText{

  margin-top: -150px;
  color: white;
  font-size: 12pt;
  background-color: rgba(0,0,0, .4);
  padding: 10px;
  border-radius: 10px;
}

.interactiveInfographic{
  filter: drop-shadow(0 0 10px #000);
  max-height:350px;
  transform: scale(0.8);
}
.interactiveInfographic:hover{
  filter: drop-shadow(0 0 10px red);
  transform: scale(0.9);
  cursor: pointer;

}
